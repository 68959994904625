import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import type { Sponsor } from '../../../types/Sponsor';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';
import SiteLogo from '../SiteLogo/SiteLogo';

import styles from './styles/powered-by.css';

loadCSS('powered-by');

interface Props {
  sponsor?: Sponsor;
  className?: string;
}

const PoweredBy = ({ sponsor, className }: Props): JSX.Element => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        translate,
        editorial,
        genericSharedComponents: { LazyImage },
      } = value;
      let imgEl;

      if (sponsor) {
        imgEl = (
          <LazyImage
            {...{
              src: sponsor.logo,
              alt: sponsor.name,
              width: 70,
              height: 40,
            }}
          />
        );
      }
      const containerStyle = className && styles[className] ? className : 'container';

      return (
        <div className={styles[containerStyle]}>
          {(!editorial || sponsor) && (
            <div className={styles['inner-container']}>
              <div className={styles['powered-by-container']}>{translate('poweredByLabel')} </div>
              <SiteLogo className="powered-by" />
            </div>
          )}
          {sponsor ? (
            <div className={styles['inner-container']}>
              <span className={styles['associated-description']}>
                {translate('poweredByAssociationLabel')}{' '}
              </span>
              {sponsor.link ? (
                <a
                  {...{
                    className: styles['associated-link'],
                    href: sponsor.link,
                    target: '_blank',
                    rel: 'sponsored noopener',
                    referrerPolicy: 'no-referrer-when-downgrade',
                    draggable: 'false',
                    'data-google-interstitial': 'false',
                  }}
                >
                  {imgEl}
                </a>
              ) : (
                imgEl
              )}
            </div>
          ) : null}
        </div>
      );
    }}
  </Consumer>
);

export default PoweredBy;
